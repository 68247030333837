// Fonctions réutilisables pour les composants incluant des ValidationProvider

/**
 * Identifie automatiquement les champs obligatoires (si le label est non vide)
 */
const computedLabelClass = function () {
  if (!this.label) {
    return "d-none";
  }
  // Si on a une computed, on l'utilise à la place de la donnée brute
  let rules = this.computedRules ?? this.rules;
  // Syntaxe objet
  if (rules?.required === true || rules?.required === "true") {
    return "required";
  }
  // Syntaxe string
  return rules?.includes("required") ? "required" : "";
}

/**
 * A appeler pour calculer l'état de validation d'un champ
 */
const getValidationState = function ({ dirty, validated, valid = null }) {
  return dirty || validated ? valid : null;
}

/**
 * Méthode de focus par défaut à surcharger pour les composants plus complexes
 * 
 * Nécessite d'avoir les références suivantes dans le composant : 
 * - input : champ où placer le focus
 */
const focus = function () {
  this.$refs.input.focus();
}

/**
 * Méthode de validation de champ par défaut à surcharger pour les composants plus complexes
 * 
 * Nécessite d'avoir les références suivantes dans le composant : 
 * - observer : ValidationObserver utilisé pour capturer l'état de validation du champ ou form
 * - input : champ où placer le focus en cas d'invalidité remontée par l'observer
 */
const validate = async function () {
  if (!(await this.$refs.observer.validate())) {
    this.$refs.input.focus();
    throw new Error("Invalid field");
  }
}

/**
 * Méthode permettant de capturer l'event d'input sur le champ à valider
 * De façon à pouvoir gérer le bug à la 1ère validation (lorsque la valeur initiale est nulle)
 * Dans ce cas, on déclenche une revalidation manuelle du champ APRES mise à jour de la valeur
 */
const onInput = async function (value) {
  let initialValue = this.value;
  if (value !== initialValue) {
    this.$emit("input", value);
  }
  if (!initialValue) {
    await this.$nextTick();
    this.$refs.observer.validate();
  }
};

const computed = {
  computedLabelClass
};
const methods = {
  getValidationState,
  focus,
  validate,
  onInput,
};

export { computed, methods };