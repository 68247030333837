<template>
  <div class="alert alert-danger" role="alert" v-if="error" @click.stop="hide">
    <!--
    <strong>{{ `${error.status || ""} ${error.error || ""}` }}</strong>
    -->
    {{ error.message || "" }}
  </div>
</template>

<script>
export default {
  name: "ErrorComponent",
  props: ["error"],
  methods: {
    hide() {
      this.$emit("hide", this.error?.id);
    },
  },
};
</script>
