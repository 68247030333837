<template>
  <ValidationObserver ref="observer" slim>
    <ValidationProvider :rules="computedRules" v-slot="validationContext">
      <b-form-group
        :id="`group-${id}`"
        :label="label"
        :label-for="`input-${id}`"
        :label-cols="labelCols"
        :label-class="computedLabelClass"
        :description="description"
      >
        <b-input-group>
          <b-form-input
            ref="input"
            :id="`input-${id}`"
            :name="`input-${id}`"
            :type="computedType"
            :state="getValidationState(validationContext)"
            :aria-describedby="`feedback-${id}`"
            :value="value"
            @input="(value) => $emit('input', value)"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              ref="passwordToggle"
              class="py-0 px-2"
              variant="outline-primary"
              @click="hidden = !hidden"
            >
              <b-icon-eye v-if="hidden"></b-icon-eye>
              <b-icon-eye-slash v-else></b-icon-eye-slash>
            </b-button>
            <b-tooltip
              :target="() => $refs['passwordToggle']"
              triggers="hover"
              variant="primary"
            >
              <template v-if="hidden">Afficher le mot de passe</template>
              <template v-else>Masquer le mot de passe</template>
            </b-tooltip>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback
          :id="`feedback-${id}`"
          :state="getValidationState(validationContext)"
        >
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  computed as validationComputed,
  methods as validationMethods,
} from "../../services/validation.service";
export default {
  name: "InputPasswordComponent",
  components: { ValidationObserver, ValidationProvider },
  /**
   * value = valeur de l'input
   * label = label du form group
   * labelCols = labelCols du form group
   * description = description du form group
   * rules = règles de validation à appliquer (aucune par défaut)
   */
  props: ["value", "label", "labelCols", "description", "rules"],
  data: () => ({
    id: uuidv4(),
    hidden: true,
  }),
  computed: {
    ...validationComputed,
    computedType() {
      return this.hidden ? "password" : "text";
    },
    computedRules() {
      return this.rules ?? "required";
    },
  },
  methods: {
    ...validationMethods,
  },
};
</script>
