<template>
  <b-container fluid @keydown.enter="onSubmit">
    <b-row class="vh-100" align-v="center" align-h="center">
      <b-card
        no-body
        :img-src="logoSrc"
        img-alt="Logo Fermes Bio"
        img-top
        style="min-width: 350px; max-width: 350px"
        class="p-3"
      >
        <b-card-title class="mt-4">{{ title }}</b-card-title>
        <!-- Zone d'affichage des erreurs de connexion -->
        <b-card-text v-if="$store.state.auth.error">
          <ErrorComponent
            :error="$store.state.auth.error"
            @hide="$store.state.auth.error = null"
          ></ErrorComponent>
        </b-card-text>
        <b-card-text>
          <ValidationProvider rules="required|email" v-slot="validationContext">
            <b-form-group
              id="group-email"
              label="Email"
              label-for="input-email"
            >
              <b-form-input
                id="input-email"
                name="input-email"
                type="email"
                :state="getValidationState(validationContext)"
                aria-describedby="input-email-feedback"
                v-model="email"
              ></b-form-input>
              <b-form-invalid-feedback id="input-email-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-card-text>
        <!-- Mode LOGIN -->
        <b-card-text v-if="modeLogin">
          <InputPasswordComponent
            label="Mot de passe"
            v-model="password"
          ></InputPasswordComponent>
        </b-card-text>
        <!-- Mode FORGOTTEN -->
        <b-card-text v-else>
          <em>
            En cliquant sur le bouton ci-dessous, vous recevrez un mail
            contenant un lien temporaire vous permettant de r&eacute;initialiser
            votre mot de passe.
          </em>
        </b-card-text>
        <!-- Boutons du formulaire -->
        <b-card-text class="text-center">
          <b-button
            block
            pill
            variant="primary"
            :disabled="loading"
            @click="onSubmit"
          >
            <b-icon-stopwatch v-if="loading"></b-icon-stopwatch>
            <b-icon-person-check v-else></b-icon-person-check>
            {{ submitButtonLabel }}
          </b-button>
        </b-card-text>
        <template #footer>
          <b-card-text class="text-center">
            <b-button variant="link" @click="modeLogin = !modeLogin">
              {{ toggleButtonLabel }}
            </b-button>
          </b-card-text>
        </template>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import ErrorComponent from "../components/ErrorComponent.vue";
import InputPasswordComponent from "../components/inputs/InputPasswordComponent.vue";
import AccountService from "../services/account.service";
import { methods as validationMethods } from "../services/validation.service";
import UtilsService from "../services/utils.service";
export default {
  name: "LoginView",
  components: {
    ValidationProvider,
    ErrorComponent,
    InputPasswordComponent,
  },
  data() {
    return {
      email: null,
      password: null,
      modeLogin: true,
      loading: false,
    };
  },
  computed: {
    logoSrc() {
      return this.$store.getters["societeFromHostname"](location.hostname).logo;
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    title() {
      return this.modeLogin ? "Connexion" : "Mot de passe oublié";
    },
    submitButtonLabel() {
      return this.modeLogin ? "Connexion" : "Envoyer le mail";
    },
    toggleButtonLabel() {
      return this.modeLogin ? "Mot de passe oublié" : "Connexion";
    },
    expirationMotDePasse() {
      // Nombre de jours avant expiration du mot de passe
      let expiration = this.$store.state.auth.user.expirationMotDePasse;
      let diffMillis = new Date(expiration) - Date.now();
      return Math.ceil(diffMillis / (1000 * 60 * 60 * 24));
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: "accueil" });
    }
  },
  methods: {
    ...validationMethods,
    async onSubmit() {
      this.loading = true;
      await (this.modeLogin ? this.login() : this.declareForgottenPassword());
      this.loading = false;
    },
    async login() {
      try {
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
          origin: location.origin,
        });
        if (this.expirationMotDePasse < 15) {
          this.$router.push({ name: "changement-mot-de-passe" });
        } else {
          this.$router.push({ name: "accueil" });
        }
      } catch (error) {
        this.$store.state.auth.error = UtilsService.handleError(error);
      }
    },
    async declareForgottenPassword() {
      this.loading = true;
      try {
        await AccountService.declareForgottenPassword(this.email);
        this.modeLogin = true;
      } catch (error) {
        this.$store.state.auth.error = UtilsService.handleError(error);
      }
    },
  },
};
</script>
